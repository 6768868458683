import React, { memo, useRef, useLayoutEffect } from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import styled from "styled-components";
import { gsap } from "gsap";

import PageLayout from "../layouts/PageLayout";

import mcdonalds from "../images/icons/partners/mcdonalds.svg";
import kusmitea from "../images/icons/partners/kusmitea.svg";
import vapiano from "../images/icons/partners/vapiano.svg";
import theroyalteas from "../images/icons/partners/theroyalteas.svg";
import jourdefete from "../images/icons/partners/jourdefete.svg";
import museemaillol from "../images/icons/partners/museemaillol.svg";
import precisiontopo from "../images/icons/partners/precisiontopo.svg";
import topex from "../images/icons/partners/topex.svg";
import adopteuntoit from "../images/icons/partners/adopteuntoit.svg";

const logos1 = [
	{
		src: mcdonalds,
		link: "https://www.mcdonaldsreunion.com/",
		alt: "Mcdonald's",
	},
	{
		src: kusmitea,
		link: "https://www.kusmitea.re/",
		alt: "kusmi Tea",
	},
	{
		src: vapiano,
		link: "https://www.vapiano.fr/",
		alt: "Vapiano",
	},
	{
		src: theroyalteas,
		link: "https://theroyalteas.shop/",
		alt: "The Royal Tea",
	},
	{
		src: jourdefete,
		link: "https://www.jourdefete.re/",
		alt: "Jour de Fête",
	}
];

const logos2 = [
	{
		src: museemaillol,
		link: "https://www.museemaillol.com/",
		alt: "Museee Maillol",
	},
	{
		src: precisiontopo,
		link: "https://www.precision-topo.com/",
		alt: "Precision Topo",
	},
	{
		src: topex,
		link: "https://www.topex.fr/",
		alt: "Topex",
	},
	{
		src: adopteuntoit,
		link: "https://www.adopteuntoit.com/",
		alt: "Adopte un Toit",
	}
];

const exitPage = (element) => gsap.to(element, { x: -20, opacity: 0, duration: 0.5 });


const seoParams = {
	title: "Acceuil"
};

const PartnersStyle = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 65px;
	border-radius: 10px;
	padding: 20px 50px 30px 35px;
	width: min-content;
	background-color: rgba(34, 34, 34, 0.1);

	> div {
		overflow: hidden;
		width: calc(100vw - 200px);
		max-width: calc(170px * 5);
	}

	p {
		margin-bottom: 25px;
		font-weight: ${props => props.theme.mediumWeight};
	}

	@media (max-width: 768px) {
		> div {
			width: calc(100vw - 150px);
		}
	}
`

const SliderStyle = styled.div`
	display: flex;
	animation: slide 34s linear infinite;

	img {
		margin: 0 20px;
		min-width: 130px;
		max-width: 130px;
		height: 50px;
		object-fit: contain;
	}

	&:first-of-type {
		margin-bottom: 30px;
		animation: slideReverse 38s linear infinite;
	}

	@keyframes slide {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(calc(-170px * 4));
		}
	}

	@keyframes slideReverse {
		0% {
			transform: translateX(calc(-170px * 5));
		}

		100% {
			transform: translateX(0);
		}
	}
`

const Partners = memo(() => (
	<PartnersStyle>
		<p>Ils m'ont fait confiance</p>
		<div>
			<SliderStyle>
				{logos1.map(({ link, src, alt }) => (
					<a href={link} target="_blank" rel="noopener noreferrer" key={src}>
						<img src={src} alt={alt} />
					</a>
				))}
				{logos1.map(({ link, src, alt }) => (
					<a href={link} target="_blank" rel="noopener noreferrer" key={src}>
						<img src={src} alt={alt} />
					</a>
				))}
			</SliderStyle>
			<SliderStyle>
				{logos2.map(({ link, src, alt }) => (
					<a href={link} target="_blank" rel="noopener noreferrer" key={src}>
						<img src={src} alt={alt} />
					</a>
				))}
				{logos2.map(({ link, src, alt }) => (
					<a href={link} target="_blank" rel="noopener noreferrer" key={src}>
						<img src={src} alt={alt} />
					</a>
				))}
				{logos2.map(({ link, src, alt }) => (
					<a href={link} target="_blank" rel="noopener noreferrer" key={src}>
						<img src={src} alt={alt} />
					</a>
				))}
			</SliderStyle>
		</div>
	</PartnersStyle>
));

const IndexPage = memo(() => {
	const animationParent = useRef();

	useLayoutEffect(() => {
		const selector = gsap.utils.selector(animationParent);
		const animation = gsap.from(selector("h1, p, a, div"), {
			duration: .8,
			ease: "power3.inOut",
			y: 20,
			opacity: 0,
			stagger: .1
		});

		return () => animation.kill();
	}, []);

	return (
		<PageLayout seo={seoParams} ref={animationParent}>
			<h1 className="display" style={{ lineHeight: 1.2 }}>Imaginer, concevoir, développer.<br />
				<span className="h1">Vos projets digitaux de A à Z</span></h1>

			<p className="small" style={{ opacity: 0.85, marginBottom: 40 }}><strong>Une application mobile</strong>, un <strong>site internet vitrine</strong> ? <strong>E-Commerce</strong> ? Vous avez besoin d’un <strong>logo</strong> ou d’une <strong>identité graphique</strong> ? Peut-être des <strong>photos</strong> ou du <strong>contenu graphique</strong> ?<br />
				De la <strong>conception graphique</strong> au <strong>développement de vos idées</strong>, je mets en œuvre tout mon savoir créatif et technique, pour réaliser des <strong>projets digitaux</strong> en cohérence avec votre image.</p>

			<TransitionLink
				style={{ padding: "14px 18px" }}
				exit={{
					trigger: ({ node }) => exitPage(node),
					length: 1
				}}
				className="button"
				to="/contact">
				Prendre contact
			</TransitionLink>

			<Partners />
		</PageLayout>
	);
});

export default IndexPage;